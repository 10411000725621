<template>
  <div :class="logoClass">
    <div v-if="isAdmin" class="d-flex">
      <img v-if="animated" class="animated-logotipo" :src="logoAnimated" />
      <img v-else class="logotipo" :src="logoTipo" />
      <img class="logo-img" :src="getWhitelabelLogo(whitelabelConfiguration.whitelabel)" />
    </div>
    <img v-else class="logotipo-img" :src="logo" />
  </div>
</template>

<script>
import logoAnimated from '@/assets/animated-logo.gif'
import logoTipo from '@/assets/animated-logo-fixed.png'
import logoMercafacil from '@/assets/mercafacil-dark.svg'
import logoSmart from '@/assets/smart-dark.svg'
import logoTribanco from '@/assets/tribanco-dark.svg'
import { mapState } from 'pinia'

import { useStore } from '@/store'

export default {
  name: 'LogoLogin',

  props: {
    animated: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    ...mapState(useStore, {
      source: 'getCurrentSource',
      logo: 'getCurrentLogo',
      isAdmin: 'getIsAdmin',
    }),
    logoAnimated() {
      return logoAnimated
    },
    logoTipo() {
      return logoTipo
    },
    whitelabelConfiguration() {
      return this.$getWhitelabelConfiguration()
    },
    logoClass() {
      if (this.isAdmin) {
        return {
          mercafacil: 'mercafacil-logo',
          smart: 'smart-logo',
          tribanco: 'tribanco-logo',
        }[this.whitelabelConfiguration.whitelabel]
      }
      return {
        INTEGRATION_DEVELOPER: 'integration-developer-logo',
        STARLORD: 'starlord-logo',
        STORYBOOK: 'storybook-logo',
        AMBEV: 'ambev-logo',
      }[this.source]
    },
  },

  methods: {
    getWhitelabelLogo(whitelabel) {
      return {
        mercafacil: logoMercafacil,
        smart: logoSmart,
        tribanco: logoTribanco,
      }[whitelabel]
    },
  },
}
</script>

<style lang="scss">
.integration-developer-logo,
.storybook-logo,
.starlord-logo,
.mercafacil-logo,
.smart-logo,
.tribanco-logo {
  display: inline-flex;
  width: 100%;
  justify-content: center;
}

.integration-developer-logo {
  .logotipo {
    width: 200px;
    margin-bottom: 30px;
  }
}

.storybook-logo {
  .logotipo-img {
    width: 300px;
  }
}

.starlord-logo {
  .logotipo-img {
    width: -webkit-fill-available;
    width: stretch;
    width: -moz-available;
  }
}

.mercafacil-logo {
  margin-left: -20px;

  .logotipo {
    width: 107px;
  }

  .animated-logotipo {
    width: 107px;
  }

  .logo-img {
    width: 170px;
    margin-top: -5px;
  }
}

.smart-logo {
  .logotipo {
    width: 50px;
    position: absolute;
    top: 120px;
    left: 170px;
  }

  .animated-logotipo {
    width: 50px;
    position: absolute;
    top: 120px;
    left: 170px;
  }

  .logo-img {
    width: 200px;
  }
}

.tribanco-logo {
  .logotipo {
    width: 50px;
    position: absolute;
    top: 100px;
    left: 164px;
  }

  .animated-logotipo {
    width: 50px;
    position: absolute;
    top: 100px;
    left: 164px;
  }

  .logo-img {
    width: 200px;
  }
}

.ambev-logo {
  text-align: center;
  .logo-img {
    width: 300px;
    height: 90px;
  }
}
</style>
